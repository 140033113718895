import { type IDBPDatabase, openDB, type DBSchema } from 'idb';
import type { FeedItemDTO } from '$lib/server/entities/feed/BaseFeedEntity';
import { remove_feed_item_duplicates } from '../feed_utils';

export type FeedState = {
                            key: FeedKey,
                            feed_items: FeedItemDTO[],
                            last_update_timestamp: Date
                        };

interface FeedStateDatabase extends DBSchema {
    feed_state: {
        value: FeedState;
        key: FeedKey;
    }
}

type FeedKey = 'primary_feed';

const PERSISTANT_DATABASE_NAME = 'feed_items_database';
const PERSISTANT_DATABASE_VERSION = 1;
const PRIMARY_FEED_KEY: FeedKey = 'primary_feed';

class FeedStateStore {

    private db?: IDBPDatabase<FeedStateDatabase>;

    private async open_idb(): Promise<IDBPDatabase<FeedStateDatabase>> {

        if (this.db === undefined) {

            this.db = await openDB<FeedStateDatabase>(PERSISTANT_DATABASE_NAME, PERSISTANT_DATABASE_VERSION, {
                upgrade(upgrade_db) {
        
                    if (!upgrade_db.objectStoreNames.contains('feed_state')) {
                        upgrade_db.createObjectStore('feed_state', {keyPath: 'key'});
                    }
                }
            });
        }

        return this.db;
    }

    async get_feed(feed_key = PRIMARY_FEED_KEY)
        : Promise<FeedState | undefined> {

        const db = await this.open_idb();
        return db.get('feed_state', feed_key);
    }

    async set_feed_state(feed_items: FeedItemDTO[], feed_key = PRIMARY_FEED_KEY) {
        
        let deduped_feed_items = remove_feed_item_duplicates(feed_items);
        let deproxied_feed_items = JSON.parse(JSON.stringify(deduped_feed_items));

        const feed_state: FeedState = {
            key: feed_key,
            feed_items: deproxied_feed_items,
            last_update_timestamp: new Date()
        };

        const db = await this.open_idb();
        return db.put('feed_state', feed_state);
    }

    async clear_feed_state(feed_key = PRIMARY_FEED_KEY) {
        
        const db = await this.open_idb();
        return db.delete('feed_state', feed_key);
    }
}

const feed_state_store = new FeedStateStore();
export default feed_state_store;